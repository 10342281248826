:root {
  --color-black: #000;
  --color-white: #fff;
  --color-light-pink: #FFE3EE;
  --color-pink: #D25A8A;
  --color-dark-pink: #75284C;
  --color-cream: #FEFBEA;
  --color-light-grey: #EEECEC;
  --color-dark-grey: #4A3D40;
  --font-primary: "PJS", Arial, Helvetica, sans-serif;
  --text-color: #a1a1a1;
  --shadow: rgba(32, 32, 32, 0.1);
  --slider-color-1: rgba(117, 40, 76, 0.65);
  --slider-color-2: rgba(117, 40, 76, 0);
}

/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

body {
  color: var(--text-color);
  font: 600 87.5%/1.5 var(--font-primary);
}

.box-content {
  padding-left: 2%;
  padding-right: 2%;
}
@media (min-width: 768px) {
  .box-content {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.is-responsive-image {
  display: block;
  height: auto;
  width: 100%;
}

.is-responsive-picture * {
  display: block;
  height: auto;
  width: 100%;
}

.main-header {
  align-items: center;
  background-color: var(--color-white);
  box-sizing: border-box;
  display: flex;
  height: 90px;
  justify-content: space-between;
  left: 0;
  padding-bottom: 18px;
  padding-top: 9px;
  position: fixed;
  transition: background-color 0.5s;
  top: 0;
  width: 100%;
  z-index: 202;
}
.main-header.has-navigation {
  background-color: var(--color-dark-pink);
}

.menu-trigger {
  background: none;
  border: none;
}

.menu-trigger--icon {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  transition: transform 400ms;
  user-select: none;
  width: 48px;
}
.menu-trigger--icon .line {
  fill: none;
  stroke: var(--color-pink);
  stroke-width: 5.5;
  stroke-linecap: round;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
}
.has-navigation .menu-trigger--icon .line {
  stroke: var(--color-white);
}
.menu-trigger--icon .top {
  stroke-dasharray: 40 139;
}
.menu-trigger--icon .bottom {
  stroke-dasharray: 40 180;
}
.menu-trigger--icon.active {
  transform: rotate(45deg);
}
.menu-trigger--icon.active .top {
  stroke-dashoffset: -98px;
}
.menu-trigger--icon.active .bottom {
  stroke-dashoffset: -138px;
}

.main-logo {
  display: block;
  text-align: center;
  width: 240px;
}
.main-logo > * {
  height: 40px;
  width: auto;
}
.has-navigation .main-logo {
  display: none;
}
@media (min-width: 768px) {
  .main-logo > * {
    height: auto;
    width: 100%;
  }
}

.main-header__action > span {
  display: none;
}
.has-navigation .main-header__action {
  display: none;
}
@media (min-width: 768px) {
  .main-header__action > svg {
    display: none;
  }
  .main-header__action > span {
    display: inline;
  }
}

.main-navigation {
  background-color: var(--color-dark-pink);
  box-sizing: border-box;
  color: var(--color-white);
  height: 100%;
  left: -100%;
  padding: 90px 5%;
  position: fixed;
  top: 0;
  transition: left 0.7s;
  width: 100%;
  z-index: 201;
}

.main-navigation--visible {
  left: 0;
}

.main-navigation__item {
  margin-bottom: 30px;
}

.main-navigation__link {
  color: var(--color-white);
  font-size: 1.786rem;
  line-height: 1.3;
  text-decoration: none;
}

.main-navigation__section {
  display: block;
  font-size: 0.786rem;
  text-transform: uppercase;
}

.donation {
  background-color: var(--color-dark-pink);
  color: var(--color-white);
  overflow: hidden;
  left: 0;
  padding-bottom: 6px;
  padding-top: 6px;
  position: fixed;
  top: 90px;
  width: 100%;
  z-index: 200;
}

.donation__link {
  color: var(--color-white);
  text-decoration: none;
}

.donation__text {
  margin-right: 15px;
}
@media (max-width: 767px) {
  .donation__text {
    font-size: 0.8rem;
  }
}

.donation__button {
  align-items: center;
  border: 1px solid var(--color-white);
  border-radius: 12px;
  display: inline-flex;
  gap: 6px;
  margin-left: 6px;
  padding: 2px 6px;
}

.section-name {
  font-size: 0.786rem;
  text-transform: uppercase;
}

.section-name--pink {
  color: var(--color-dark-pink);
}

.title-2 {
  color: var(--color-dark-grey);
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .title-2 {
    font-size: 2.286rem;
  }
}

.title-2--pink {
  color: var(--color-pink);
}

.title-2--dark-pink {
  color: var(--color-dark-pink);
}

.title-2--spaced {
  margin-bottom: 21px;
}

.title-2--light {
  color: var(--color-white);
}

.title-3 {
  color: var(--color-dark-grey);
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 9px;
}

.main-footer {
  padding-bottom: 60px;
  padding-top: 60px;
}
@media (min-width: 768px) {
  .main-footer {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: repeat(2, 1fr);
  }
}

.main-footer__logo {
  display: block;
}
.main-footer__logo > * {
  height: 60px;
  width: auto;
}

.main-footer__credits {
  margin-top: 12px;
}
.main-footer__credits a {
  color: var(--color-pink);
  text-decoration: none;
}

.main-footer__follow {
  margin-bottom: 21px;
}

.main-footer__follow-title {
  color: var(--color-pink);
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.main-footer__follow-items {
  align-items: center;
  display: flex;
  gap: 9px;
}

.main-footer__follow-link {
  align-items: center;
  background-color: var(--color-light-pink);
  border-radius: 50%;
  display: flex;
  fill: var(--color-pink);
  height: 45px;
  justify-content: center;
  width: 45px;
}

.main-footer__menu {
  display: grid;
  grid-column-gap: 2%;
  grid-row-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 960px) {
  .main-footer__menu {
    grid-column-gap: 15%;
    grid-template-columns: repeat(3, 1fr);
  }
}

.main-footer__menu-link {
  color: var(--color-pink);
  text-decoration: none;
}

.main-footer__menu-section {
  color: var(--color-dark-pink);
  font-size: 0.786rem;
  display: block;
  min-height: 18px;
  text-transform: uppercase;
}

.main-footer__menu-label {
  font-size: 1.1rem;
}

.button {
  align-items: center;
  background-color: var(--color-pink);
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 16px var(--shadow);
  color: var(--color-white);
  display: inline-flex;
  justify-content: center;
  font-weight: normal;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s;
}
.button:hover, .button:focus {
  background-color: var(--color-light-pink);
  color: var(--color-dark-pink);
}
@media (min-width: 768px) {
  .button {
    padding: 18px 25px;
  }
}

.button--highlight {
  text-transform: uppercase;
}

.form {
  text-align: left;
}

.form__wrapper {
  margin-top: 21px;
}
@media (min-width: 768px) {
  .form__wrapper {
    align-items: center;
    display: flex;
    gap: 21px;
    justify-content: center;
  }
}

.form__label {
  color: var(--text-color);
  display: block;
  margin-bottom: 3px;
}

.form__input {
  border: none;
  border-radius: 15px;
  box-sizing: border-box;
  font-size: 1.333rem;
  height: 42px;
  margin-bottom: 12px;
  padding: 0 12px;
  width: 100%;
}

.form__actions {
  margin-top: 30px;
  text-align: center;
}

.contacts {
  background-color: var(--color-dark-pink);
  color: var(--color-white);
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
}

.contacts__content {
  padding-left: 2%;
  padding-right: 2%;
}
@media (min-width: 960px) {
  .contacts__content {
    padding-left: 20%;
    padding-right: 20%;
  }
}

.contacts__form {
  margin-top: 52px;
}
.contacts__form p {
  font-size: 1.1rem;
}