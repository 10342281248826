.main-header{
  align-items: center;
  background-color: var(--color-white);
  box-sizing: border-box;
  display: flex;
  height: 90px;
  justify-content: space-between;
  left: 0;
  padding-bottom: 18px;
  padding-top: 9px;
  position: fixed;
  transition: background-color .5s;
  top: 0;
  width: 100%;
  z-index: 202;

  &.has-navigation{
    background-color: var(--color-dark-pink);
  }
}

.menu-trigger{
  background: none;
  border: none;
}

.menu-trigger--icon{
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  transition: transform 400ms;
  user-select: none;
  width: 48px;

  .line {
    fill: none;
    stroke: var(--color-pink);
    stroke-width: 5.5;
    stroke-linecap: round;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;

    .has-navigation &{
      stroke: var(--color-white);
    }
  }

  .top {
    stroke-dasharray: 40 139;
  }

  .bottom {
    stroke-dasharray: 40 180;
  }

  &.active {
    transform: rotate(45deg);

    .top {
      stroke-dashoffset: -98px;
    }

    .bottom {
      stroke-dashoffset: -138px;
    }
  }
}

.main-logo{
  display: block;
  text-align: center;
  width: 240px;

  > *{
    height: 40px;
    width: auto;
  }

  .has-navigation &{
    display: none;
  }

  @media (min-width: 768px) {
    > *{
      height: auto;
      width: 100%;
    }
  }
}

.main-header__action{
  > span{
    display: none;
  }

  .has-navigation &{
    display: none;
  }

  @media (min-width: 768px) {
    > svg{
      display: none;
    }

    > span{
      display: inline;
    }
  }
}