:root{
  --color-black: #000;
  --color-white: #fff;
  --color-light-pink: #FFE3EE;
  --color-pink: #D25A8A;
  --color-dark-pink: #75284C;
  --color-cream: #FEFBEA;
  --color-light-grey: #EEECEC;
  --color-dark-grey: #4A3D40;
  --font-primary: 'PJS', Arial, Helvetica, sans-serif;
  --text-color: #a1a1a1;
  --shadow: rgba(32, 32, 32, 0.1);
  --slider-color-1: rgba(117, 40, 76, 0.65);
  --slider-color-2: rgba(117, 40, 76, 0);
}
