.main-footer{
  padding-bottom: 60px;
  padding-top: 60px;

  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: repeat(2, 1fr);
  }
}

.main-footer__logo{
  display: block;

  > *{
    height: 60px;
    width: auto;
  }
}

.main-footer__credits{
  margin-top: 12px;

  a{
    color: var(--color-pink);
    text-decoration: none;
  }
}

.main-footer__follow{
  margin-bottom: 21px;
}

.main-footer__follow-title{
  color: var(--color-pink);
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.main-footer__follow-items{
  align-items: center;
  display: flex;
  gap: 9px;
}

.main-footer__follow-link{
  align-items: center;
  background-color: var(--color-light-pink);
  border-radius: 50%;
  display: flex;
  fill: var(--color-pink);
  height: 45px;
  justify-content: center;
  width: 45px;
}

.main-footer__menu{
  display: grid;
  grid-column-gap: 2%;
  grid-row-gap: 12px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 960px) {
    grid-column-gap: 15%;
    grid-template-columns: repeat(3, 1fr);
  }
}

.main-footer__menu-link{
  color: var(--color-pink);
  text-decoration: none;
}

.main-footer__menu-section{
  color: var(--color-dark-pink);
  font-size: 0.786rem;
  display: block;
  min-height: 18px;
  text-transform: uppercase;
}

.main-footer__menu-label{
  font-size: 1.1rem;
}