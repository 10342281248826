.form{
  text-align: left;
}

.form__wrapper{
  margin-top: 21px;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    gap: 21px;
    justify-content: center;
  }
}

.form__label{
  color: var(--text-color);
  display: block;
  margin-bottom: 3px;
}

.form__input{
  border: none;
  border-radius: 15px;
  box-sizing: border-box;
  font-size: 1.333rem;
  height: 42px;
  margin-bottom: 12px;
  padding: 0 12px;
  width: 100%;
}

.form__actions{
  margin-top: 30px;
  text-align: center;
}