body{
  color: var(--text-color);
  font: 600 87.5%/1.5 var(--font-primary);
}

.box-content{
  padding-left: 2%;
  padding-right: 2%;

  @media (min-width: 768px) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.is-responsive-image{
  display: block;
  height: auto;
  width: 100%;
}

.is-responsive-picture{
  *{
    display: block;
    height: auto;
    width: 100%;
  }
}