.main-navigation{
  background-color: var(--color-dark-pink);
  box-sizing: border-box;
  color: var(--color-white);
  height: 100%;
  left: -100%;
  padding: 90px 5%;
  position: fixed;
  top: 0;
  transition: left .7s;
  width: 100%;
  z-index: 201;
}

.main-navigation--visible{
  left: 0;
}

.main-navigation__item{
  margin-bottom: 30px;
}

.main-navigation__link{
  color: var(--color-white);
  font-size: 1.786rem;
  line-height: 1.3;
  text-decoration: none;
}

.main-navigation__section{
  display: block;
  font-size: 0.786rem;
  text-transform: uppercase;
}