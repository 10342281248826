.button{
  align-items: center;
  background-color: var(--color-pink);
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 16px var(--shadow);
  color: var(--color-white);
  display: inline-flex;
  justify-content: center;
  font-weight: normal;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  transition: color .3s, background-color .3s;

  &:hover,
  &:focus{
    background-color: var(--color-light-pink);
    color: var(--color-dark-pink);
  }

  @media (min-width: 768px) {
    padding: 18px 25px;
  }
}

.button--highlight{
  text-transform: uppercase;
}