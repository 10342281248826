.donation{
  background-color: var(--color-dark-pink);
  color: var(--color-white);
  overflow: hidden;
  left: 0;
  padding-bottom: 6px;
  padding-top: 6px;
  position: fixed;
  top: 90px;
  width: 100%;
  z-index: 200;
}

.donation__link{
  color: var(--color-white);
  text-decoration: none;
}

.donation__text{
  margin-right: 15px;

  @media (max-width: 767px) {
    font-size: 0.8rem;
  }
}

.donation__button{
  align-items: center;
  border: 1px solid var(--color-white);
  border-radius: 12px;
  display: inline-flex;
  gap: 6px;
  margin-left: 6px;
  padding: 2px 6px;
}
