.contacts{
  background-color: var(--color-dark-pink);
  color: var(--color-white);
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
}

.contacts__content{
  padding-left: 2%;
  padding-right: 2%;

  @media (min-width: 960px) {
    padding-left: 20%;
    padding-right: 20%;
  }
}

.contacts__form{
  margin-top: 52px;

  p{
    font-size: 1.1rem;
  }
}