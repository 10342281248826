.section-name{
  font-size: 0.786rem;
  text-transform: uppercase;
}

.section-name--pink{
  color: var(--color-dark-pink);
}

.title-2{
  color: var(--color-dark-grey);
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 2.286rem;
  }
}

.title-2--pink{
  color: var(--color-pink);
}

.title-2--dark-pink{
  color: var(--color-dark-pink);
}

.title-2--spaced{
  margin-bottom: 21px;
}

.title-2--light{
  color: var(--color-white);
}

.title-3{
  color: var(--color-dark-grey);
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 9px;
}